$(document).ready(function(){
	
	/* Project pages */
	var player = Plyr.setup('.player');
	$('.player').bind('play', function() {
		activated = this;
		$('.player').each(function() {
			if(this != activated) this.pause();
		});
	});

	$("#gallery-loop .active").css('z-index', 3);	
	$("#gallery-loop").removeClass("hide");
	
	/* Homepage */
	function cycleImages(){
	  var $active = $('#gallery-loop .active');
	  var $next = ($active.next().length > 0) ? $active.next() : $('#gallery-loop figure:first');
	  $next.css('z-index', 2);//move the next image up the pile
	  
	  $active.fadeOut(1500,function(){//fade out the top image
			$active.css('z-index',1).show().removeClass('active');//reset the z-index and unhide the image
	    $next.css('z-index',3).addClass('active');//make the next image the top one
	  });
	}
	setInterval(cycleImages, 4500);
	
});